
import userModel , {userPlaceholder} from "@/models/user"
import Vue from "vue";
import {UserById } from "@/repositories/user"
export default Vue.extend({
  name: "users-list",
  components:{
    
  },
  data() {
      return {
          loading : true,
          user : userPlaceholder,
          cars : [],
          tickets : [],
          viewable : [
              'Id',
              'Name',
              'Email',
              'Img',
              'Phone',
          ]
      }
  },   
  methods:{
      edit(){
          
          const currentPath  = this.$route.path
          const path = currentPath.replace("view", "edit");
          this.$router.push(path)
      }
  } ,
  created()  {
      UserById(parseInt(this.$route.params.id)).then((res:userModel) => {
          this.user = res
          this.loading = false
      })
   
  }
});
