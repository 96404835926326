type user  = {
   User : {
    Id : number,
    Admin : boolean,
    Name : string,
    Email : string,
    Img : string,
    City_id : number,
    Phone : string,
    Password : string,
    CreatedAt : string,
   }
}


export const userPlaceholder:user = {
    User : {
        Id : 0,
        Admin : true,
        Name : "",
        Email : "",
        Img : "",
        City_id : 0,
        Phone : "",
        Password : "",
        CreatedAt : "",
       }
}
export default user 
